import React from 'react'

// Localization
import 'dayjs/locale/es-mx';

// Custom providers
import IndexProvider from './Providers/IndexProvider';
import FiltersProvider from './Components/Filters/FiltersProvider';
import FeedsStateProvider from './Providers/FeedsStateProvider';
import { PostsProvider } from './Providers/PostsProvider';
import { PostsFeedSubProvider } from './Providers/PostsFeedSubProvider';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import withProviders, { ProviderWithOptionalProps } from '../Utils/withProviders';

const providersWithParams: ProviderWithOptionalProps[] = [
  IndexProvider,
  FiltersProvider,
  FeedsStateProvider,
  PostsProvider,
  PostsFeedSubProvider
];

const PostsFeedApp: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <>
    {children}
  </>;
};

export default withAuthenticationRequired(withProviders(PostsFeedApp, providersWithParams));
