import React, { useContext } from "react";

import { useQuery } from "@apollo/client";
import InputLabel from "@mui/material/InputLabel";
import {
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";

// MUI
import { styled } from '@mui/material/styles';

import FormControl from "@mui/material/FormControl";
import { GET_ARCHIVES } from "../../../../Querys";
import { Archive } from "../../../../Types/Archive";
import { BASE_STATUSES, FEEDS, FeedsStateContext } from "../../Providers/FeedsStateProvider";


const StyledFormControl = styled(FormControl)(({ theme }) => ({
    color: 'inherit',
    // width: '100%',
    [theme.breakpoints.down('sm')]: {
        width: '100%'
    }
    // '& .MuiInputBase-input': {
    //     padding: theme.spacing(1, 1, 1, 0),
    //     // vertical padding + font size from searchIcon
    //     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    //     transition: theme.transitions.create('width'),
    //     width: '100%',
    //     [theme.breakpoints.up('sm')]: {
    //         width: '50ch',
    //         '&:focus': {
    //             width: '60ch',
    //         },
    //     },
    // },
}));

const InputSelectStatus: React.FC = (props) => {
    const { useFeedState: [feedsState, setFeedsState] } = useContext(FeedsStateContext);

    const { loading, error, data: { archives } = { archives: [] } } = useQuery<{ archives: Archive[] }>(GET_ARCHIVES, {});

    if (loading) console.log("Loading User Archives for Select Status...");
    if (loading) return null;
    if (error) return <>{JSON.stringify(error)}</>;

    return (
        <StyledFormControl size="small">
            <InputLabel sx={{ color: 'white' }} id="filter-status-id">Status</InputLabel>
            <Select
                sx={{
                    color: "white",
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '.MuiSvgIcon-root ': {
                        fill: "white !important",
                    }
                }}
                labelId="filter-status-id"
                id="filter-status-select"
                value={feedsState.toString()}
                label="Status"
                onChange={(event: SelectChangeEvent) => {
                    setFeedsState(event.target.value as FEEDS | number);
                }}
            >
                {BASE_STATUSES.map((status, i) => (<MenuItem key={i} value={status.value}>{status.label}</MenuItem>))}
                {archives.map((archive, i) => (<MenuItem key={i} value={archive.id}>{archive.description}</MenuItem>))}
            </Select>
        </StyledFormControl>
    );
};

export default InputSelectStatus;
