import { Auth0Provider, withAuthenticationRequired, Auth0ProviderOptions } from '@auth0/auth0-react';
import React from "react";
import { useNavigate } from 'react-router-dom';

export const ProtectedRoute: React.FC<{ component: React.FC<{ props?: {} }>; props?: string; }> =
  ({ component, props }) => {
    const Component: React.FC<{ props?: {} }> =
      withAuthenticationRequired(component);

    return <Component props={props} />;
  };

const Auth0ProviderWithRedirectCallback = ({ children, ...props }: { children: React.ReactNode } & Auth0ProviderOptions) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: any) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

export const Auth0Config: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <Auth0ProviderWithRedirectCallback
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
        redirect_uri: window.location.origin
      }}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      cacheLocation="localstorage"
    >
      {props.children}
    </Auth0ProviderWithRedirectCallback>
  )
}