import React from 'react';
import { FormControl, FormControlLabel, FormGroup, FormLabel, Switch, SwitchProps, Typography, styled } from "@mui/material";
import { useQuery } from '@apollo/client';
import { GET_ARCHIVES } from '../../../../Querys';
import { Archive } from '../../../../Types/Archive';

const WIDTH = 65;
const HEIGHT = 40;

// #FF7900
const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: WIDTH,
  height: HEIGHT,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(26px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#26a69a',
        // backgroundColor: theme.palette.mode === 'dark' ? '#FF5F15' : `#FF5F15`,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 35,
    height: 35,
  },
  '& .MuiSwitch-track': {
    borderRadius: HEIGHT / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));



interface ArchivesInputProps {
  value: number[];
  onChange: (archives: number[]) => void;
};

const ArchivesInput: React.FC<ArchivesInputProps> = ({ value, onChange }) => {

  const { loading, error, data } = useQuery<{ archives: Archive[] }>(GET_ARCHIVES, {});

  if (loading) return <>Loading User Archives...</>
  if (error) return <>{JSON.stringify(error)}</>;

  var userArchives = (data !== undefined) ? data.archives : [];

  // value = (value && value.length < 1 && filters.status === POST_STATES.PENDING) ? [userArchives[0].id] : value;

  return (
    <FormControl sx={{ my: 2 }} component="fieldset" variant="standard" >
      <FormLabel component="legend">Archives</FormLabel>
      <FormGroup >
        {
          userArchives.map((archive, index) =>
            <FormControlLabel
              sx={{
                paddingRight: `15px`,
                ":hover": {
                  backgroundColor: `#f1f1f1`,
                  borderBottomRightRadius: 15,
                  borderTopRightRadius: 15
                }
              }}
              key={archive.id}
              control={
                <IOSSwitch
                  checked={value.includes(archive.id)}
                  sx={{ m: 1 }}
                  onChange={(event, checked) => {
                    console.log(checked)
                    if (checked)
                      if (!value.includes(archive.id)) {
                        onChange([...value, archive.id])
                      }
                      else onChange(value);
                    else
                      if (value.includes(archive.id))
                        onChange(value.filter(function (item) {
                          return item !== archive.id
                        }))
                      else onChange(value);
                  }}
                  name={archive.description} />
              }
              label={
                <Typography
                  fontSize={22} sx={{ fontWeight: 'bold', color: '#4f4f4f' }}>{archive.description}</Typography>
              }
            />
          )
        }
      </FormGroup>
    </FormControl >
  );
};
export default ArchivesInput;