import React, { createContext } from 'react';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import legacyTheme from './Themes/legacy';

interface ThemeContextProps {
  toggleTheme: () => void;
  mode: 'light' | 'dark';
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);


export const ThemesConfiguration: React.FC<{ children: React.ReactNode }> = (props) => {

  const getPreferredTheme = (): 'light' | 'dark' => {
    const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    return systemPrefersDark ? 'dark' : 'light';
  };

  const [mode, setMode] = React.useState<'light' | 'dark'>(getPreferredTheme);

  React.useEffect(() => {
    const listener = (e: MediaQueryListEvent) => {
      setMode(e.matches ? 'dark' : 'light');
    };

    const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');
    matchMedia.addEventListener('change', listener);

    return () => matchMedia.removeEventListener('change', listener);
  }, []);

  const theme: Theme = React.useMemo(() => {
    return createTheme({
      ...legacyTheme,
      palette: {
        ...legacyTheme.palette,
        mode: undefined,
      },
    });
  }, [mode]);

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeContext.Provider value={{ toggleTheme, mode }}>
      <ThemeProvider theme={theme}>
        {props.children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}