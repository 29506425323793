import { Button } from '@mui/material';
import React, { useContext } from 'react'
import { FiltersContext, SORT_ORDER } from './FiltersProvider';
import { SortByAlpha } from '@mui/icons-material';

type Props = {}

const TickerSortOrderToggleBtn = (props: Props) => {
  const [filters, setFilters] = useContext(FiltersContext);

  return (
    <Button onClick={(event) => {
      if (filters.orderAlphabetically === null)
        setFilters(f => ({ ...f, orderAlphabetically: SORT_ORDER.ASC }))
      else if (filters.orderAlphabetically === SORT_ORDER.ASC)
        setFilters(f => ({ ...f, orderAlphabetically: SORT_ORDER.DESC }))
      else
        setFilters(f => ({ ...f, orderAlphabetically: null }))
    }} sx={{ color: 'white', border: 'white' }} variant="outlined" startIcon={<SortByAlpha />}>
      {
        (filters.orderAlphabetically !== null) ?
          filters.orderAlphabetically
          : 'none'
      }
    </Button>
  )
}

export default TickerSortOrderToggleBtn;