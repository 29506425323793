import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import Layout, { useLayout } from '../Components/Layout/Layout';
import { Box, createFilterOptions, FormControl, styled } from '@mui/material';
import { GoBackBtn } from '../Components/Layout/AppBar';
import TickerInputSelectBase from '../Components/PostsFeed/Components/commons/TickerInputSelect';
import { useAuth0 } from '@auth0/auth0-react';
import { getNewPost } from '../Components/PostsFeed/Providers/PostsProvider';
import PostEditor from '../Components/PostsFeed/Components/PostEditor/PostEditor';
import { Post } from '../Types/Post';
import { useQuery } from '@apollo/client';
import { GET_POST_BY_ID } from '../Querys';
import { Ticker } from '../Types/Ticker';

const PostScreen = () => {

  const { id } = useParams<{ id?: string }>();
  const { user } = useAuth0();

  var [post, setPost] = React.useState<Post>(getNewPost());

  const { loading, data: { post: cachedPost } = { post: null } } = useQuery<{ post: Post }>(GET_POST_BY_ID, {
    skip: id == null,
    variables: { id },
    onCompleted: ({ post }) => {
      setPost(post)
    }
  });


  if (Number.isNaN(id)) return null;
  if (user === undefined || user.sub === undefined) throw new Error("No User");

  var userId = Number(user.sub.split('|')[1]);

  return (
    <Layout menu={
      <Menu
        post={post}
        onChange={setPost}
      />}>
      <PostEditor
        post={post}
        onChange={setPost}
        userId={userId}
      />
    </Layout>
  )
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  color: 'inherit',
  margin: '.3rem',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

const TickerInputSelect = styled(TickerInputSelectBase)(({ theme }) => ({
  marginLeft: 1, minWidth: 200, my: 1,
  color: "white",
  svg: { color: 'white' },
  input: { color: ' white' },
  label: {
    color: 'white'
  },
  "& .MuiInputLabel-root": {
    "&.MuiInputLabel-sizeSmall": {
      fontSize: "1.25rem",
      paddingTop: ".5rem"
    }
  },
  "text-fill-color": "white !important",
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(228, 219, 233, 0.25)',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(228, 219, 233, 0.25)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(228, 219, 233, 0.25)',
  },
  '.MuiSvgIcon-root ': {
    fill: "white !important",
  }
}));

const filterOptions = createFilterOptions<Ticker>({
  matchFrom: 'start',
});

const Menu = ({ post, onChange: setPost }: { post: Post, onChange: React.Dispatch<React.SetStateAction<Post>> }) => {
  // const [isSearchEnable, setIsSearchEnable] = useState(false);
  // const [isDrawerOpen, setIsDrawerOpen] = useLayout();
  return (
    <Box sx={{ display: 'flex', flex: '0 0 100%', flexDirection: 'column', padding: '0 10px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flex: { sx: 1 }, alignItems: 'center' }}>
          <GoBackBtn />
        </Box>
        <Box
          onScroll={(event) => { }}
          component="div"
          sx={{ display: 'flex', flex: 1, justifyContent: 'left', overflowX: 'auto', overflow: 'auto' }}
        >
          <StyledFormControl size="small">
            <TickerInputSelect
              value={post.ticker}
              autoHighlight
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              autoSelect
              disabled={!post.active}
              filterOptions={filterOptions}
              blurOnSelect={true}
              getOptionLabel={(tickerOption) => tickerOption.ticker || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, ticker) => {
                if (ticker)
                  setPost({
                    ...post,
                    news_ticker_id: ticker.id,
                    ticker: ticker
                  });
              }}
              size="small"
            />
          </StyledFormControl>
        </Box>
      </Box>
    </Box>
  )
}

export default PostScreen;