import React from "react";

// import { useAuth0 } from "@auth0/auth0-react";

import { useNavigate } from "react-router-dom";

// MUI
import { styled } from '@mui/material/styles';

import {
  ArrowBack as ArrowBackIcon,
  Create as CreateIcon,
} from "@mui/icons-material";

import Box from "@mui/system/Box";
import {
  Button,
  Toolbar,
  AppBar as ReactAppBar,
} from "@mui/material";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'flex-start',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  // Override media queries injected by theme.mixins.toolbar
  '@media all': {
  },
}));

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  createBtn?: boolean;
  filters?: boolean;
  menu?: React.ReactElement | JSX.Element | JSX.Element[];
};

const AppBar: React.FC<Props> = (props) => {

  const appBarRef = React.useRef<HTMLDivElement>(null);
  const [appBarHeight, setAppHeight] = React.useState(90);

  React.useEffect(() => {

    const handleResize = () => {
      if (appBarRef.current)
        setAppHeight(appBarRef.current.offsetHeight)
    };

    handleResize();
    window && window.addEventListener('resize', handleResize);
    window && window.addEventListener('scroll', handleResize);

    return () => {
      window && window.removeEventListener('scroll', handleResize);
    }
  }, []);

  React.useEffect(() => {
    if (appBarRef.current)
      setAppHeight(appBarRef.current.offsetHeight)
    return () => { }
  });

  return (

    <Box sx={{ display: 'flex', mb: `${appBarHeight}px` }} >
      {/* <HideOnScroll > */}
      <ReactAppBar ref={appBarRef}>
        <StyledToolbar disableGutters>
          {props.menu}
        </StyledToolbar>
      </ReactAppBar>
      {/* </HideOnScroll> */}
      <Box component="div">

      </Box>

    </Box >
  )
}

export default AppBar;

const CustomButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  height: `34px`,
  width: `34px`,
  border: '1px solid',
  '&:hover': {
    backgroundColor: theme.palette.contrastThreshold,
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  }
}));

export const GoBackBtn = () => {
  const navigate = useNavigate();
  return <CustomButton onClick={() => { navigate(`/news`) }}>
    <ArrowBackIcon sx={{ fontSize: 40 }} />
  </CustomButton>
}

export const CreateNewPostBtn = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <CustomButton onClick={() => { navigate(`/news/post`) }}>
        <CreateIcon sx={{ fontSize: `2rem`, }} />
      </CustomButton>
    </Box >
  )
}
