import { gql } from "@apollo/client";

export var PostFragment = gql`
  fragment PostFragment on Post {
    id
    title
    news_date
    updated_at
    created_at
    active
    stock_value_change
    ticker {
      id
      description
      ticker
    }
    rejected
    aknowledge_delete
    content
    content_type
    archives
    submit_user_id
    isNew @client
  }
`;

export var POST_SUBSCRIPTION = gql`
  subscription ToPostChanges {
    subPost {
      ...PostFragment
    }
  }
  ${PostFragment}
`;

export var GET_POSTS = gql`
  query getNews( 
      $id: Int
      $active: Boolean
      $aknowledgeDelete: Boolean

      $tickersUserId: Int

      $tickerId: Int
      $archives: [Int]

      $fromPostDate: AWSDate
      $untilPostDate: AWSDate

      $searchClauseOnTittle: String
      $searchClauseOnContent: String 

      $submitUserId: Int

      $offset: Int
      $limit: Int
      $orderBy: [PostSortOrder!]
  ) {
    posts(
      postsParameters:{
        id: $id
        active: $active
        aknowledgeDelete: $aknowledgeDelete

        tickersUserId: $tickersUserId

        tickerId: $tickerId
        archives: $archives

        fromPostDate: $fromPostDate
        untilPostDate: $untilPostDate 

        searchClauseOnTittle: $searchClauseOnTittle
        searchClauseOnContent: $searchClauseOnContent

        submitUserId: $submitUserId

        offset: $offset
        limit: $limit
        orderBy: $orderBy
      }
    ) {
      content
      ...PostFragment
    }
  }
  ${PostFragment}
`;

export var GET_POSTS_TOTAL = gql`
  query getNewsTotal(
      $active: Boolean
      $aknowledgeDelete: Boolean

      $tickersUserId: Int

      $tickerId: Int
      $archives: [Int]

      $fromPostDate: AWSDate
      $untilPostDate: AWSDate

      $searchClauseOnTittle: String
      $searchClauseOnContent: String 

      $submitUserId: Int

      $offset: Int
      $limit: Int
      $orderBy: [PostSortOrder!]
  ) {
    postsCount(
      postsParameters:{
        active: $active
        aknowledgeDelete: $aknowledgeDelete
        tickersUserId: $tickersUserId

        tickerId: $tickerId
        archives: $archives

        fromPostDate: $fromPostDate
        untilPostDate: $untilPostDate 

        searchClauseOnTittle: $searchClauseOnTittle
        searchClauseOnContent: $searchClauseOnContent

        submitUserId: $submitUserId

        offset: $offset
        limit: $limit
        orderBy: $orderBy
      }
    ) {
      total
    }
  }
`;

export var GET_POST_BY_ID = gql`
  query GetPostById($id: Int!) {
    post(id: $id) {
      content
      content_type

      lock_version

      rejected
      rejected_at
      rejected_by

      created_at
      submit_user_id

      updated
      updated_at
      modify_user_id
      aknowledge_delete
      archives
      ...PostFragment
    }

    # userPostArchives(postId: $id) {
    #   id
    #   user_id
    #   description
    #   active
    # }
  }
  ${PostFragment}
`;

export var CREATE_NEW_POST = gql`
  mutation CreatePost($post: PostCreateInput!) {
    createPost(post: $post) {
      ...PostFragment
    }
  }
  ${PostFragment}
`;

export var UPDATE_POST = gql`
  mutation updatePost($post: PostUpdateInput!) {
    updatePost(post: $post) {
      content
      content_type

      lock_version

      rejected
      rejected_at
      rejected_by

      created_at
      submit_user_id

      updated
      updated_at
      modify_user_id
      aknowledge_delete
      ...PostFragment
    }
  }
  ${PostFragment}
`;

export var DELETE_POST = gql`
  mutation deletePost($id: Int!) {
    deletePost(id: $id) {
      ...PostFragment
    }
  }
  ${PostFragment}
`;

export var ARCHIVE_POST = gql`
  mutation archivePost($id: Int! $archives:[Int!]!) {
    archivePost(postId: $id, archives: $archives)
  }
`;

export var GET_ARCHIVES = gql`
  query GetArchives {
    archives {
      id
      description
    }
  }
`;

export var GET_POST_ARCHIVES = gql`
  query GetPostArchives($postId: Int!) {
     postArchives( 
      postId: $postId
     )
  }
`;

export var GET_TICKERS = gql`
  query GetTickers {
    tickers {
      id
      description
      ticker
    }
  }
`;

export var GET_POST_LOGS_EVENTS_BY_ID = gql`
query getPostLogEvents ($id:Int!){
  postLogEvents(id:$id){
    id
    event_type
    event_by
    event_at
    user{
      id
      name
    }
    }
}
`;