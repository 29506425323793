import React from "react";

import Grid from "@mui/material/Unstable_Grid2";
import { IconButton, } from "@mui/material";

import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";

// Using PostsFeed Filters
import InputSelectStatus from "../PostsFeed/Components/Filters/InputSelectStatus";
import TickerFilter from "../PostsFeed/Components/Filters/TickersFilter";
import OrderByFilterInput from "../PostsFeed/Components/Filters/OrderByFiterInput";
import TickerSortOrderToggleBtn from "../PostsFeed/Components/Filters/TickerSortOrderToggleBtn";
import AnalystsFilterInput from "../PostsFeed/Components/Filters/AnalystsFilterInput";
import DateRangeFilterInput from "../PostsFeed/Components/Filters/DateRangeFilterInput";

interface Iprops {
  onSearchClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  isSearchEnable?: boolean;
}

const FiltersBar: React.FC<Iprops> = (props) => {
  return (
    <Grid
      container
      wrap="nowrap"
      spacing={0}
      alignItems="center"
      justifyContent="center"
      sx={{ color: "white", flexWrap: 'wrap', p: '5px 0' }}
    >
      <Box>
        <IconButton sx={{ color: (!props.isSearchEnable) ? 'white' : 'gray' }}
          onClick={props.onSearchClick} aria-label="delete" size="large">
          <SearchIcon fontSize="inherit" />
        </IconButton>
      </Box>

      <Box sx={{ mx: 1 }}>
        <InputSelectStatus />
      </Box>

      <Box >
        <TickerFilter />
      </Box>

      <Box>
        <TickerSortOrderToggleBtn />
      </Box>

      <Box>
        <OrderByFilterInput />
      </Box>

      <Box sx={{ display: `flex`, mx: 1 }}>
        <DateRangeFilterInput />
      </Box>

      <Box>
        <AnalystsFilterInput />
      </Box>

    </Grid>
  );
};

export default FiltersBar;
