// SettingsContext.tsx
import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';

const GET_SETTINGS = gql`
  query GetSettings {
    settings {
      priceProvider
    }
  }
`;

interface Settings {
  priceProvider: string;
}

const defaultSettings: Settings = {
  priceProvider: 'YahooFinanceAPI',
};

interface SettingsContextProps {
  settings: Settings;
  updateSettings: (newSettings: Settings) => void;
}

const SettingsContext = createContext<SettingsContextProps | undefined>(undefined);

export const useSettings = (): SettingsContextProps => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};

interface SettingsProviderProps {
  children: ReactNode;
}

export const SettingsProvider: React.FC<SettingsProviderProps> = ({ children }) => {
  const [settings, setSettings] = useState<Settings>(() => {
    const savedSettings = localStorage.getItem('app-settings');
    return savedSettings ? JSON.parse(savedSettings) : defaultSettings;
  });

  const { data, loading, error } = useQuery(GET_SETTINGS, {
    skip: !!settings, // Omitir la consulta si ya tenemos settings en localStorage
  });

  useEffect(() => {
    if (data && !settings) {
      setSettings(data.settings);
      localStorage.setItem('app-settings', JSON.stringify(data.settings));
    }
  }, [data, settings]);

  const updateSettings = (newSettings: Settings) => {
    setSettings(newSettings);
    localStorage.setItem('app-settings', JSON.stringify(newSettings));
    // Aquí puedes agregar la lógica para guardar los settings en tu backend si es necesario
  };

  return (
    <SettingsContext.Provider value={{ settings, updateSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};
