import React from 'react'

// Theming
import './App.css';

// Data
import { ApolloConfiguration } from './ApolloConfigurationProvider';
import { ThemesConfiguration } from './ThemesMui/ThemesConfigurations';

// Localization
import 'dayjs/locale/es-mx';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// Screens components

// componentes

// Custom providers
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Routing from './Routing';

import PostsFeedApp from './Components/PostsFeed/PostsFeedApp';
import { ModalProvider } from './Components/wip/ModalContext';
import withProviders, { ProviderWithOptionalProps } from './Components/Utils/withProviders';
import { SettingsProvider } from './Components/wip/SettingsContext';

// import "./Components/Chat/chat.css";

const providersWithParams: ProviderWithOptionalProps[] = [
  ApolloConfiguration,
  ThemesConfiguration,
  SettingsProvider,
  [LocalizationProvider, { dateAdapter: AdapterDayjs, adapterLocale: 'en', localeText: { start: 'From', end: 'Until' } }],
  ModalProvider
];

// ===
const App = () => {
  //Data 
  return (
    <PostsFeedApp>
      <Routing />
    </PostsFeedApp>
  )
};

export default withAuthenticationRequired(withProviders(App, providersWithParams));


// const App = withProviders(() => {
//   //Data 
//   return (
//     <PostsFeedApp>
//       <Routing />
//     </PostsFeedApp>
//   )
// }, providersWithParams);