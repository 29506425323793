import React, { createContext, useContext } from "react";

import CssBaseline from '@mui/material/CssBaseline';
import AppBar from "./AppBar";
import { Drawer } from "@mui/material";
import MobileMenu from "./MobileMenu";
interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    children: React.ReactElement | JSX.Element | JSX.Element[];
    menu?: React.ReactElement | JSX.Element | JSX.Element[];
}

const Layout: React.FC<Props> = (props) => {
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

    return (
        <LayoutContent.Provider value={[isDrawerOpen, setIsDrawerOpen]}>
            <CssBaseline />
            <AppBar menu={props.menu} />
            <Drawer
                container={window !== undefined ? () => window.document.body : undefined}
                variant="temporary"
                open={isDrawerOpen}
                onClose={() => { setIsDrawerOpen(isOpen => !isOpen) }}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{

                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                }}
            >
                <MobileMenu onClick={() => { setIsDrawerOpen(isOpen => !isOpen) }} />
            </Drawer>
            {/* {queryData.loading ? < LinearProgress /> : null} */}
            {props.children}

        </LayoutContent.Provider>
    );
};

var LayoutContent = createContext<[boolean | null, React.Dispatch<React.SetStateAction<boolean>>]>([null, (i) => {
    throw new Error("Function uninitialize ");
}]);

export const useLayout = () => useContext(LayoutContent);

export default Layout;