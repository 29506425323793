import React, { useCallback } from 'react';
import { Grid } from '@mui/material';
import StockValueInput from '../../commons/StockValueInput';
import { Post } from '../../../../../Types/Post';
import useStockValue from '../../../../../Services/NewsGraphqlAPI/Hooks/useStockValue';

interface Props {
  post: Post;
  cachedPost: Post | null;
  setPost: React.Dispatch<React.SetStateAction<Post>>;
}

const PostEditorStockValue: React.FC<Props> = ({ post, cachedPost, setPost }) => {
  const { ticker, news_date, stock_value_change } = post;
  const cachedTicker = cachedPost?.ticker?.ticker;

  const isCachedPostDifferent = cachedPost == null
    || (ticker != null && (cachedTicker == null || cachedTicker !== ticker.ticker))
    || !news_date.isSame(cachedPost.news_date, 'day');

  const handleStockValueChange = useCallback((stockValue: string) => {
    setPost(prevPost => {
      return {
        ...prevPost,
        stock_value_change: stockValue
      }
    });
  }, [setPost]);

  const handleFetchValue = useCallback((stockValue: string) => {
    if (isCachedPostDifferent) {
      handleStockValueChange(stockValue);
    }
  }, [isCachedPostDifferent, handleStockValueChange]);

  const { handleRefresh } = useStockValue({
    ticker: ticker ? ticker.ticker : null,
    date: news_date, // TODO: Revisar como funciona los timezones
    onFetchValue: handleFetchValue,
    onRefresh: handleStockValueChange
  });

  return (
    <Grid container direction="column" alignItems="left" spacing={2}>
      <Grid item>
        <StockValueInput
          value={stock_value_change}
          onChange={handleStockValueChange}
          onRefresh={handleRefresh}
        />
      </Grid>
    </Grid>
  );
};

export default PostEditorStockValue;
