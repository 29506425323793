import React, { useContext } from 'react'
import UserAnalystInputSelect from '../commons/UserAnalystInputSelect'
import { FiltersContext } from './FiltersProvider';
import { styled } from '@mui/material/styles';
import { FormControl } from '@mui/material';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  color: 'inherit',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

type Props = {}

const AnalystsFilterInput = (props: Props) => {
  const [filters, setFilters] = useContext(FiltersContext);

  return (
    <StyledFormControl size="small">
      <UserAnalystInputSelect
        value={filters.analyst}
        onChange={(event, user) => {
          setFilters(f => ({ ...f, analyst: user }))
        }}
        sx={{
          color: "white",
          svg: { color: 'white' },
          input: { color: ' white' },
          label: { colo: 'white' },
          "& .MuiInputLabel-root": {
            "&.MuiInputLabel-sizeSmall": {
              fontSize: "1.5rem",
            }
          },
          "text-fill-color": "white !important",
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(228, 219, 233, 0.25)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(228, 219, 233, 0.25)',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(228, 219, 233, 0.25)',
          },
          '.MuiSvgIcon-root ': {
            fill: "white !important",
          }
        }}
        size="small" style={{ minWidth: 140 }} />
    </StyledFormControl>
  )

}

export default AnalystsFilterInput