import React from 'react';
import { createTheme } from '@mui/material/styles';
import { blue } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    ticker: React.CSSProperties;
    date: React.CSSProperties;
    time: React.CSSProperties;
    postContent: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    ticker?: React.CSSProperties;
    date?: React.CSSProperties;
    time?: React.CSSProperties;
    postContent?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    ticker: true;
    postContent: true;
    date: true;
    time: true;
    h1: false;
    h2: false;
    h3: false;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    legacy: true;
  }
}

declare module "@mui/material" {
  interface ButtonPropsColorOverrides {
    reject: true;
    delete: true;
    blue: true;
  }
}

export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

export const colourOptions: readonly ColourOption[] = [
  { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
  { value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
  { value: 'purple', label: 'Purple', color: '#5243AA' },
  { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
  { value: 'orange', label: 'Orange', color: '#FF8B00' },
  { value: 'yellow', label: 'Yellow', color: '#FFC400' },
  { value: 'green', label: 'Green', color: '#36B37E' },
  { value: 'forest', label: 'Forest', color: '#00875A' },
  { value: 'slate', label: 'Slate', color: '#253858' },
  { value: 'silver', label: 'Silver', color: '#666666' },
];
// #26a69a
const theme = createTheme({
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#26a69a",
          // color: "red",
        }
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          fontSize: "1.5rem",
          
        },
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'legacy' },
          style: {
            textTransform: 'uppercase',
            color: 'white',

            letterSpacing: `.5px`,
            border: `2px dashed grey${blue[500]}`,
            height: `54px`,
            lineHeight: `56px`,
            minWidth: `150px`,
            fontSize: `1rem`
          },
        },
        {
          props: { variant: 'legacy', color: 'primary' },
          style: {
            backgroundColor: '#26a69a',
            letterSpacing: `.5px`,
            lineHeight: `56px`,
            "&:hover": {
              backgroundColor: `#2bbbad`,
            },
          },
        },
        {

          props: { variant: 'legacy', color: 'reject' },
          style: {
            backgroundColor: '#F44336',
            "&:hover": {
              backgroundColor: `#F6685E`,
            },
          },
        },
        {
          props: { variant: 'legacy', color: 'blue' },
          style: {
            backgroundColor: '#2196F3',
            "&:hover": {
              backgroundColor: `#1769AA`,
            },
          },
        },
        // {
        //   props: { variant: 'reject' },
        //   style: {
        //     textTransform: 'none',
        //     border: `2px dashed grey${blue[500]}`,
        //   },
        // },
        // {
        //   props: { variant: 'delete' },
        //   style: {
        //     textTransform: 'none',
        //     border: `2px dashed grey${blue[500]}`,
        //   },
        // },
        // {
        //   props: { variant: 'aknowledge' },
        //   style: {
        //     textTransform: 'none',
        //     border: `2px dashed grey${blue[500]}`,
        //   },
        // },
      ]
    },
  },
  typography: {
    fontFamily: 'roboto',
    ticker: {
      color: 'black',
      fontSize: '1.2rem',
      fontWeight: 'bold'
    },
    date: {
      color: 'rgb(21, 101, 192)',
      fontWeight: '500'
    },
    time: {
      color: 'rgb(21, 101, 192)',
      fontWeight: '500'
    },
    postContent: {
      color: 'black',
      fontSize: '1.2rem',
      fontWeight: '500'
    },

    // Disable h3 variant
    h3: undefined,
  },
  palette: {
    primary: {
      main: '#26a69a',
    },
    secondary: {
      main: '#26a69a',
    },
    custom: {
      main: '#ffff'
    }
    // background: {
    //   paper: '#dfdfdf',
    //   default: '#f9f7f1',
    // },
  },
});

export default theme;