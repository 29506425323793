import React, { useContext } from 'react'

import { Box, Typography } from '@mui/material'

import { useQuery } from '@apollo/client';
import { GET_POSTS_TOTAL } from '../../../../Querys';

import { FeedsStateContext } from '../../Providers/FeedsStateProvider';
import { FiltersContext } from '../Filters/FiltersProvider';
import { postQuerySerializer, usePostsFeed } from '../../Providers/PostsProvider';

// TODO: REFACTOR THIS!!
function Counter() {
  // const { totals: { total, showingTotal } } = useContext(PostsContext);

  // Using the App Filters.
  const [filters] = useContext(FiltersContext);

  // Current Active Feeds.
  const { getFeedVariables } = useContext(FeedsStateContext);

  // const feed = useFeedState[0];
  const { posts } = usePostsFeed();

  // Getting Posts Data.
  const { data: { postsCount: { total } } = { postsCount: { total: 0 } } }
    = useQuery<{ postsCount: { total: number } }>(GET_POSTS_TOTAL, {
      variables: {
        ...postQuerySerializer(filters),
        ...getFeedVariables()
      }
    });

  // Filtering
  // TODO: For Posts Comming from the feed we need to consult if the new post match the current Feed Filtering.
  //       with this we expect only have on cache posts that match the current Filter.
  // TODO: Move this posible to the FeedsStateContext and just bring a function like: posts = feedFilter(posts);

  // posts = posts.filter((post) => {
  //   return (!post.rejected || (post.rejected && post.submit_user_id === userId))
  // });

  // switch (feed) {
  //   case FEEDS.PENDING:
  //     posts = posts.filter(({ archives }) => (archives == null) ? true : archives?.length < 1)
  //     break;
  //   case FEEDS.ARCHIVED:
  //     posts = posts.filter(({ archives }) => (archives == null) ? true : archives?.length > 0)
  //     break;
  //   case FEEDS.DELETED:
  //     posts = posts.filter(({ active }) => !active)
  //     break;
  //   default:
  //     break;
  // }

  // if (feed !== FEEDS.DELETED)
  //   posts = posts.filter((post) => {
  //     return (post.active || (post.aknowledge_delete && post.submit_user_id === userId))
  //   });

  return (
    <Box sx={{
      display: `flex`,
      alignItems: `center`,
      color: `white`,
      mx: 2
    }}>
      {/* <Typography variant="body1">{showingTotal} of {total}</Typography> */}
      <Typography variant="body1">{posts.length} of {total}</Typography>
    </Box>
  )
}

export default Counter