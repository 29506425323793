import React, { useState, createContext } from "react";

// Step 1: Create a context

export var IndexContext = createContext<[number | null, React.Dispatch<React.SetStateAction<number | null>>]>([null, (i) => { console.log(i) }]);

// Step 2: Create a context provider component
const IndexProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const currentPostState = useState<number | null>(null);

    
    return (<IndexContext.Provider value={currentPostState}>{children}</IndexContext.Provider>);
};

export default IndexProvider;