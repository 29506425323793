import React, { createContext } from "react";

import dayjs, { Dayjs } from "dayjs";
import { Ticker } from "../../../../Types/Ticker";
import { User } from "../commons/UserAnalystInputSelect";

export enum SORT_ORDER {
    ASC = 'asc',
    DESC = 'desc'
};

export type PreviewDateAttr = 'updated_at' | 'created_at' | 'news_date';

export enum DATE_ORDER_BY {
    NEWS_DATE_ASC = "news_date|asc",
    NEWS_DATE_DESC = "news_date|desc",
    CREATE_AT_ASC = "created_at|asc",
    CREATE_AT_DESC = "created_at|desc",
    UPDATED_AT_ASC = "updated_at|asc",
    UPDATED_AT_DESC = "updated_at|desc",
};

export interface Filters {
    searchClause: string | null;
    ticker: Ticker | null;
    fromPostDate: Dayjs | null;
    untilPostDate: Dayjs | null;
    analyst: User | null;

    orderDatesBy: DATE_ORDER_BY;
    orderAlphabetically: SORT_ORDER | null;

    getOrderDateBy: () => PreviewDateAttr
};

var INIT_FILTERS: Filters = {
    searchClause: null, // 1
    ticker: null, // 3
    fromPostDate: dayjs().subtract(12, 'months'), // 4
    untilPostDate: dayjs().add(1, 'day'), // 5
    orderDatesBy: DATE_ORDER_BY.UPDATED_AT_DESC, // 6
    orderAlphabetically: null, // 7
    analyst: null,

    getOrderDateBy: function () {
        switch (this.orderDatesBy.split('|')[0]) {
            case 'news_date':
                return 'news_date';
            case 'created_at':
                return 'created_at';
            default:
                return 'updated_at';
        }
    },
};

export var FiltersContext = createContext<[Filters, React.Dispatch<React.SetStateAction<Filters>>]>([INIT_FILTERS, () => { }]);

// Step 2: Create a context provider component
const FiltersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const filtersState = React.useState<Filters>({ ...INIT_FILTERS });

    return (<FiltersContext.Provider value={filtersState}>{children}</FiltersContext.Provider>);
};

export default FiltersProvider;