import React from 'react';

// Froala Editor
import FroalaEditorComponent from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

// Froala Editor: Import all Froala Editor plugins.
import 'froala-editor/js/plugins.pkgd.min.js';

import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/font_size.min.js';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

const FroalaEditor: React.FC<{ value: string | null, view?: boolean, onChange: object, placeholderText: string }> = ({ value, view = false, onChange, placeholderText }) => {
  
  if (process.env.REACT_APP_FROALA_KEY === undefined) return <>Error With Froala Key</>

  if (view)
    return <FroalaEditorView
      model={value}
      config={{
        key: process.env.REACT_APP_FROALA_KEY
      }}
    />;

  return (<>
    <FroalaEditorComponent
      model={value}
      onModelChange={onChange}
      config={{
        key: process.env.REACT_APP_FROALA_KEY,
        events: {
          'froalaEditor.initialized': function (e: any, editor: any) {
            editor.edit.off();
          }
        },
        charCounterCount: false,
        pluginsEnabled: ["colors", "table", "inlineStyle", "entities", "quote", "wordPaste", "align", "fontSize"],
        tabSpaces: false,
        editorClass: "needsclick",
        initOnClick: false,
        imagePaste: false,
        spellcheck: true,
        toolbarInline: true,
        wordAllowedStyleProps: ["color", "width", "text-align", "vertical-align", "background-color", "text-decoration", "font-weight", "font-style", "text-indent", "margin-left", "margin-right"],
        // wordAllowedStyleProps: ["font-family", "font-size", "background", "color", "width", "text-align", "vertical-align", "background-color", "padding", "margin", "height", "margin-top", "margin-left", "margin-right", "margin-bottom", "text-decoration", "font-weight", "font-style", "text-indent", "border", "border-.*"],
        wordPasteModal: false,
        wordPasteKeepFormatting: true,
        placeholderText: placeholderText,
        toolbarButtons: ["textColor", "backgroundColor", "fontSize", "insertTable", "bold", "strikeThrough", "undo", "redo", "quote", "align", "clearFormatting"],
        toolbarButtonsMD: ["textColor", "backgroundColor", "fontSize", "insertTable", "bold", "strikeThrough", "undo", "redo", "quote", "align", "clearFormatting"],
        toolbarButtonsSM: ["textColor", "backgroundColor", "fontSize", "insertTable", "bold", "strikeThrough", "undo", "redo", "quote", "align", "clearFormatting"],
        toolbarButtonsXS: ["textColor", "backgroundColor", "fontSize", "insertTable", "bold", "strikeThrough", "undo", "redo", "quote", "align", "clearFormatting"]
      }}
    />

  </>
  )
};
export default FroalaEditor;