import { InputAdornment, IconButton, FormControl, InputLabel, Input } from "@mui/material";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useState } from "react";


interface NewsStockValueChangeProps {
  value: string;
  onChange: (value: string) => void;
  onRefresh?: () => void;
}

const StockValueInput: React.FC<NewsStockValueChangeProps> = ({ value, onRefresh, onChange }) => {
  const [componentState, setComponentState] = useState({ editing: 'none' });

  const onEditFn = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    const input = event.currentTarget;
    const key = event.key;

    var validKeys = ['+', '-', '.', 'Backspace'];

    console.log(key)

    const editing = componentState.editing;

    if (editing === 'price' && (!Number.isNaN(key) || validKeys.includes(key))) {

      if (key === 'Tab') {
        setComponentState({ editing: 'change' });
        input.selectionStart = value.indexOf(',') + 2;
        input.selectionEnd = value.indexOf('%');
        event.preventDefault();
      }
    } else if (editing === 'change' && (!Number.isNaN(key) || validKeys.includes(key))) {
      if (key === 'Tab') {
        document.querySelector<HTMLButtonElement>('#submit-button')?.focus();
        event.preventDefault();
      }
    } else if (input.selectionStart === input.selectionEnd) {
      setComponentState({ editing: 'price' });
      input.selectionStart = 2;
      input.selectionEnd = value.indexOf(',');
      event.preventDefault();
    } else {
      event.preventDefault();
    }
  };

  let endAdornment = onRefresh && <InputAdornment position="end">
    <IconButton
      onClick={onRefresh}
      edge="end"
    >
      <AutorenewIcon />
    </IconButton>
  </InputAdornment>;

  return (
    <FormControl variant="standard">
      <InputLabel htmlFor="stock-value">Stock Value</InputLabel>
      <Input
        id="stock-value"
        value={value || ""}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        inputProps={{
          onKeyDown: onEditFn,
          onFocus: (event) => {
            onEditFn(event as any);
          },
          pattern: "\\(\\$\\s*\\d+\\.\\d+,\\s*(\\+|-)\\d+\\.\\d+%\\)",
        }}
        endAdornment={endAdornment}
        sx={{ input: { fontSize: '20px' } }}
      />
    </FormControl >
  );
};

export default StockValueInput;
