import React from 'react';
import { Box, Typography, Divider, List, ListItem, ListItemButton, ListItemText, MenuItem, Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../wip/ModalContext';
import EditSettings from '../wip/EditSettings';

interface MobileMenuProps {
  onClick: () => void;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ onClick }) => {
  const { user, logout } = useAuth0();

  const { openModal } = useModal();

  const navigate = useNavigate();
  if (user === undefined) throw new Error("User on Menu Drawer is not defined:", user);

  const handleOpen = () => {
    onClick();
    openModal(<EditSettings />);
  };

  return (
    <Box
      // onClick={handleDrawerToggle}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        // textAlign: 'center'
      }}>

      <Box
        sx={{
          flexGrow: 1, // Allows this box to grow and push the logout button to the bottom
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" sx={{ my: 2 }}>
          News
        </Typography>

        <Typography variant="caption" sx={{ my: 2 }}>
          {user.name} {user.nickname}
        </Typography>
        <Divider />
        <List>
          {/* <MenuItem sx={{ textAlign: 'center' }} onClick={() => { }}>News Feed</MenuItem> */}
          <ListItemButton onClick={() => { navigate('/news') }} sx={{ textAlign: 'center' }}>
            News feed
            {/* <MenuItem sx={{ textAlign: 'center' }} onClick={() => { }}>News Feed</MenuItem> */}
          </ListItemButton>
          {/* <ListItemButton onClick={() => { navigate('/chat') }} sx={{ textAlign: 'center' }}>
            News AI Chat
          </ListItemButton> */}
          <ListItemButton onClick={handleOpen} sx={{ textAlign: 'center' }}>
            Settings
          </ListItemButton>
          {[''].map((item) => (
            <ListItem key={item} disablePadding>
              <ListItemText primary={item} />
            </ListItem>
          ))}
          {/* <Button onClick={() => { logout() }}>Logout</Button> */}
        </List>
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          p: 2, // Add some padding around the logout button
        }}
      >
        <List>
          <ListItemButton onClick={() => { logout() }} sx={{ textAlign: 'center' }}>
            Logout
          </ListItemButton>
        </List>
        <Typography variant="caption" sx={{ mt: 2, fontSize: '0.75rem' }}>
          Version 2.3.2
        </Typography>
      </Box>
    </Box>
  )
};

export default MobileMenu;