import React from "react";

import DOMPurify from "dompurify";
import parse from "html-react-parser";
import dayjs from "dayjs";
import { Typography } from "@mui/material";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import { Post } from "../../../../Types/Post";
import ArchiveIcon from '@mui/icons-material/Archive';
import ErrorIcon from '@mui/icons-material/Error';
import { PreviewDateAttr } from "../Filters/FiltersProvider";



type Props = {
  dateField?: PreviewDateAttr;
  post: Post,
  active: boolean,
  ref: React.LegacyRef<HTMLElement> | undefined,
  selected?: boolean
  onClick: () => void
};

export const PostPreviewLegacy = React.forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const { post } = props;

  const cleanHTML = DOMPurify.sanitize(post.title, {
    USE_PROFILES: { html: true },
  });

  return (
    <div
      ref={ref}
      onClick={() => {
        props.onClick();
      }}
      className={`collection-item avatar expand ${!post.active ? 'deleted' : ''} `}
      style={{
        color: "black",
        cursor: 'pointer', paddingLeft: "90px",
        backgroundColor: (props.selected) ? `#B2DFDB` : ((post.isNew) ? `#CCFFD0` : undefined),
        minHeight: (post.rejected) ? '75px' : undefined
      }}
    >
      <span
        className="circle truncate"
        style={{
          borderRadius: "0px",
          fontWeight: "bold",
          paddingTop: "0px",
          height: "65px",
          textAlign: "center",
          width: "90px",
          left: "0px",
        }}
      >

        {(post.rejected) && <ErrorIcon style={{ color: `#F44336` }} fontSize="small" />}
        <Typography fontSize={`1.25rem`} fontWeight={'bold'}>
          {post.ticker ? post.ticker.description : ""}
        </Typography>

        {getDateTime(post, props.dateField)}
      </span>
      <div
        id="news-item-title"
        className="flow-text "
        style={{ lineHeight: "1.3em", marginRight: "10px" }}
      >
        {parse(cleanHTML)}
      </div>
      <span className="blue-text">{post.stock_value_change ? post.stock_value_change : ""}</span>
      <div className="secondary-content" style={{ right: "2px" }}>
        <StatusIcon post={post} />
      </div>
    </div>
  );

});


const getDateTime = (post: Post, dateFilter: PreviewDateAttr = 'updated_at') => {
  const date = post[dateFilter];
  return (
    <div>
      <div
        style={{
          color: "rgb(21, 101, 192)",
          fontSize: "14px",
          lineHeight: "10px",
        }}
      >
        {date ? dayjs(date).format('MM/DD/YYYY') : ""}
      </div>
      <div
        style={{
          color: "rgb(21, 101, 192)",
          fontSize: "14px",
          lineHeight: "20px",
        }}
      >
        {date ? dayjs(date).format("HH:mm") : ""}
      </div>
    </div>
  );
};


export default PostPreviewLegacy;

const StatusIcon: React.FC<{ post: Post }> = ({ post }) => {
  if (post.rejected)
    return <ErrorIcon style={{ color: `#F44336` }} fontSize="large" />;
  if ((post.archives && post.archives.length > 0))
    return <ArchiveIcon style={{ color: `#9e9e9e` }} fontSize="large" />
  return <MarkunreadMailboxIcon style={{ color: `#4CAF50` }} fontSize="large" />
}