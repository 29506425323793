// EditSettings.tsx
import React, { useState } from 'react';
import { Box, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { useSettings } from './SettingsContext';

const EditSettings: React.FC = () => {
  const { settings, updateSettings } = useSettings();
  const [priceProvider, setPriceProvider] = useState(settings?.priceProvider || 'IEXCloud');

  React.useEffect(() => {
    if (settings) {
      setPriceProvider(settings.priceProvider);
    }
  }, [settings]);

  React.useEffect(() => {
    if (settings) {
      updateSettings({ priceProvider });
    }
  }, [priceProvider]);

  const handlePriceProviderChange = (event: SelectChangeEvent<string>) => {
    setPriceProvider(event.target.value);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Edit Settings
      </Typography>
      <FormControl variant="outlined" fullWidth margin="normal">
        <InputLabel id="price-provider-label">Price Provider</InputLabel>
        <Select
          labelId="price-provider-label"
          value={priceProvider}
          onChange={handlePriceProviderChange}
          label="Price Provider"
        >
          {/* <MenuItem value="IEXCloud">IEXCloud</MenuItem> */}
          {/* <MenuItem value="AlphaVantage">AlphaVantage</MenuItem> */}
          <MenuItem value="YahooFinanceAPI">Yahoo Finance API</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default EditSettings;
