import React, { useContext } from 'react';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { DATE_ORDER_BY, FiltersContext } from './FiltersProvider';
import ListSubheader from '@mui/material/ListSubheader';

const OrderByFilterInput: React.FC = () => {

  const [filters, setFilters] = useContext(FiltersContext);

  return (
    <FormControl sx={{ m: .5 }}>
      <InputLabel sx={{ color: 'white' }} htmlFor="grouped-native-select">Order by Date</InputLabel>
      <Select
        value={filters.orderDatesBy}
        onChange={(event) => {
          setFilters((f) => ({ ...f, orderDatesBy: event.target.value as DATE_ORDER_BY }))
        }}
        // variant='filled'
        size="small" id="grouped-native-select"
        sx={{
          color: "white",
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(228, 219, 233, 0.25)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(228, 219, 233, 0.25)',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(228, 219, 233, 0.25)',
          },
          '.MuiSvgIcon-root ': {
            fill: "white !important",
          }
        }}
      >
        <ListSubheader >Last Updated / Modify Date</ListSubheader>
        <MenuItem sx={{ pl: 4 }} value={DATE_ORDER_BY.UPDATED_AT_ASC}>mAscending</MenuItem>
        <MenuItem sx={{ pl: 4 }} value={DATE_ORDER_BY.UPDATED_AT_DESC}>mDescending</MenuItem>
      
        <ListSubheader >Orignal Date</ListSubheader>
        <MenuItem sx={{ pl: 4 }} value={DATE_ORDER_BY.NEWS_DATE_ASC}>oAscending</MenuItem>
        <MenuItem sx={{ pl: 4 }} value={DATE_ORDER_BY.NEWS_DATE_DESC}>oDescending</MenuItem>

        <ListSubheader >Created Date</ListSubheader>
        <MenuItem sx={{ pl: 4 }} value={DATE_ORDER_BY.CREATE_AT_ASC}> cAscending</MenuItem>
        <MenuItem sx={{ pl: 4 }} value={DATE_ORDER_BY.CREATE_AT_DESC}>cDescending</MenuItem>
      </Select>
    </FormControl>
  )
}

export default OrderByFilterInput;