/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useContext, useRef } from 'react'

import { Post } from '../Types/Post';

import { Box, IconButton, setRef } from '@mui/material';

import { useQuery } from '@apollo/client';
import { GET_POSTS, GET_POSTS_TOTAL } from '../Querys';

import { postQuerySerializer, usePostsFeed } from '../Components/PostsFeed/Providers/PostsProvider';

import { FiltersContext } from '../Components/PostsFeed/Components/Filters/FiltersProvider';
import { FeedsStateContext } from '../Components/PostsFeed/Providers/FeedsStateProvider';
import { IndexContext } from '../Components/PostsFeed/Providers/IndexProvider';

import PostPreviewLegacy from '../Components/PostsFeed/Components/PostPreview/PostPreview';
import { useInView } from 'react-intersection-observer';
import Layout, { useLayout } from '../Components/Layout/Layout';
import { useNavigate } from 'react-router-dom';
import ChatSection from '../Components/Chat/components/chat-section';
import { MenuIcon } from 'lucide-react';

const OFFSET_TO_LAST = 5;

const NewsChatScreen = () => {


  return (
    <Layout menu={<Menu />}>
      <ChatSection />
    </Layout>
  )
};

const Menu = () => {
  const [isSearchEnable, setIsSearchEnable] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useLayout();
  return (
    <Box sx={{ display: 'flex', flex: '0 0 100%', flexDirection: 'column', padding: '0 10px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton color="inherit" onClick={() => {
            setIsDrawerOpen(isOpen => !isOpen);
          }} >
            <MenuIcon />
          </IconButton >
        </Box>
      </Box>
    </Box>
  )
}

export default NewsChatScreen;