import React from "react";

import { Post } from "../../../../Types/Post";

import dayjs, { Dayjs } from "dayjs";

import { useQuery } from "@apollo/client";
import { GET_POST_LOGS_EVENTS_BY_ID } from "../../../../Querys";

const getEventLabel = (eventType: string) => {
  switch (eventType) {
    case 'create':
      return 'Submitted'
    case 'update':
      return 'Modified'
    case 'delete':
      return 'Deleted'
    case 'reject':
      return 'Reject'
    case 'fix':
      return 'Fix'
    case 'acknowledged-delete':
      return 'Acknowledged delete'
    default:
      return 'unknow action';
  }
}

type User = {
  id: number
  name?: String
}

type PostEvent = {
  id: number
  news_id: number
  post: Post
  news_user_id: number
  event_type: string
  event_by: number
  user: User
  event_at: string | Dayjs
}



const PostEvents: React.FC<{ postId: number }> = ({ postId }) => {
  const { loading, error, data } = useQuery<{ postLogEvents: PostEvent[] }>(GET_POST_LOGS_EVENTS_BY_ID, { variables: { id: postId }, fetchPolicy: 'network-only' });
  if (loading) return <>Loading...</>;
  if (error) return <>"An error has occurred: " + ${error.message}</>;
  if (data === undefined) return null;
  return (
    <div>
      {data.postLogEvents.map(event =>
        <div key={event.id}>
          <span>
            <div>
              [ <strong>{dayjs(event.event_at.toString()).format('MM/DD/YY  HH:mm')}</strong> ]: <strong>{getEventLabel(event.event_type)} by </strong> {event.user.name || 'Buscando...'}
            </div>
            {/* <div>
              <strong>Submitted 
            </div> */}
          </span>
        </div>
      )}
    </div>
  );
};

export default PostEvents;
