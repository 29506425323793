import { InMemoryCache, } from "@apollo/client";
import dayjs from "dayjs";

export const cache = new InMemoryCache({
  typePolicies: {
    Post: {
      fields: {
        isNew: {
          read: (_, { variables }) => {
            if (_ === undefined)
              return false
            else return _

          }
        },
        news_date: {
          read: (news_date, { variables }) => {
            return dayjs(news_date);
          }
        }
      }
    },
    Query: {
      fields: {
        posts: {
          keyArgs: ['id'],
          merge(existing, incoming, { args }) {
            const merged = existing ? existing.slice(0) : [];
            if (incoming) {
              if (args && args['postsParameters']) {

                // Assume an offset of 0 if args.offset omitted.
                const { offset = 0 } = args['postsParameters'];
                for (let i = 0; i < incoming.length; ++i) {
                  merged[offset + i] = incoming[i];
                }
              } else {
                // It's unusual (probably a mistake) for a paginated field not
                // to receive any arguments, so you might prefer to throw an
                // exception here, instead of recovering by appending incoming
                // onto the existing array.
                merged.push.apply(merged, incoming);
              }
            }

            return merged;
          },
        },
      },
    }
  }
});
