import React, { useContext, useEffect, useRef } from 'react'

// Types
import { Post } from '../../../../Types/Post';
import { Archive } from '../../../../Types/Archive';

// Utils
import dayjs from 'dayjs';
import _ from 'lodash';

// Navegation
import { useNavigate } from 'react-router-dom';

// Auth0


// Data Handling
import { gql, useMutation, useQuery } from "@apollo/client";
import { ARCHIVE_POST, CREATE_NEW_POST, DELETE_POST, GET_ARCHIVES, GET_POST_BY_ID, UPDATE_POST } from '../../../../Querys';

import { PostsContext, getNewPost, } from '../../Providers/PostsProvider';

// MUI
import {
  Box,
  Button,
  Typography
} from '@mui/material';
import { DesktopDateTimePicker } from '@mui/x-date-pickers-pro';

import TextField from '@mui/material/TextField/TextField';

// Common Componentes
import TickerInputSelect from '../commons/TickerInputSelect';
import ArchivesInput from '../commons/ArchivesInput';
import FroalaEditor from '../../../Commons/FroalaEditor';

// Custom componentes
import PostEvents from './PostsEvents';

// Custom WIP Componentes
import PostEditorStockValue from './Components/PostEditorStockValue';

enum EDITOR_ACTIONS {
  CREATE = 'submit',
  MODIFY = 'modify',
  DELETE = 'delete',

  ARCHIVE = 'archive',
  UNARCHIVE = 'unarchive',
  UPDATE_ARCHIVES = 'update archives',

  REJECT = 'reject',
  FIX = 'fix',

  AKNOWLEDGE_DELETE = 'aknowledge delete'
};

interface PostEditorProps {
  userId: number; // Add this line to include the 'user' prop
  post: Post;
  onChange: React.Dispatch<React.SetStateAction<Post>>;
}

const PostEditor: React.FC<PostEditorProps> = ({
  // id,
  userId,
  post,
  onChange: setPost
}) => {

  const { refechCount } = useContext(PostsContext);
  const navigate = useNavigate();

  const mainBtnRef = useRef<HTMLButtonElement>(null);

  // Estado para guardar el post inicial
  const [savedPostState, setSavedPostState] = React.useState(post.id ? post : getNewPost());

  // useEffect para actualizar savedPostState solo cuando cambia el ID del post
  useEffect(() => {
    if (post && post.id !== savedPostState?.id) {
      setSavedPostState(post); // Actualiza solo cuando cambia el ID
    }
  }, [post?.id]);

  var [actions, setActions] = React.useState<EDITOR_ACTIONS[]>([]);

  const [addPost] = useMutation<{ createPost: Post }>(CREATE_NEW_POST, { onCompleted: ({ createPost }) => { } });
  const [updatePost] = useMutation<{ post: Post }>(UPDATE_POST, { onCompleted: ({ post }) => { } });
  const [deletePost] = useMutation<{ post: Post }>(DELETE_POST, { onCompleted: ({ post }) => { } });

  // WATCH FOR CACHE CHANGE
  const [archivePost] = useMutation<{ archivePost: number[] }>(ARCHIVE_POST, {
    update: (cache, { data, }, { variables }) => {
      if (variables && data) {
        cache.writeFragment({
          fragment: gql`
          fragment PostFragment on Post {
            archives
          }`,
          data: {
            archives: data.archivePost
          },
          id: `Post:${variables['id']}`
        });
      }
    },
    optimisticResponse: { archivePost: post.archives || [] },
  });

  const { data: { archives } = { archives: [] } } = useQuery<{ archives: Archive[] }>(GET_ARCHIVES, {});

  useEffect(() => {
    if (post) {
      const postIsArchived = post.archives != null && post.archives.length > 0;
      if (!postIsArchived && archives.length > 0)
        setPost(post => {
          return {
            ...post,
            archives: [archives[0].id],
          }
        });
    }
  }, [savedPostState, archives, setPost]);

  // Keyboard Handling
  useEffect(() => {
    const handleKeyDownOnPost = async (event: KeyboardEvent) => {

      let key = event.key;

      if (key === 'Enter') {
        console.log('enter');
        event.preventDefault();
        event.stopPropagation();

        if (mainBtnRef.current) {
          console.log(mainBtnRef)
          mainBtnRef.current.click();
        }
      }
    }
    document.addEventListener('keydown', handleKeyDownOnPost);
    return () => {
      console.log('unmouting')
      document.removeEventListener('keydown', handleKeyDownOnPost);
    }
  }, []);

  // Main Action handler
  useEffect(() => {
    function processActions() {
      let actions: EDITOR_ACTIONS[] = [];
      var isNew = !('id' in post);

      if (savedPostState && !isNew) {
        var areTitleChanges = post.title !== savedPostState.title;
        var areContentChanges = post.content !== savedPostState.content;
        var valueChange = post.stock_value_change !== savedPostState.stock_value_change;
        var newsDateChange = !post.news_date.isSame(savedPostState.news_date, 'day');

        if (areTitleChanges || areContentChanges || newsDateChange || valueChange) {
          actions.push(EDITOR_ACTIONS.MODIFY)
          if (savedPostState.rejected)
            actions.push(EDITOR_ACTIONS.FIX)
        }
      } else if (!('id' in post))
        actions.push(EDITOR_ACTIONS.CREATE)

      //Archives
      if (savedPostState && !isNew && savedPostState.archives != null && post.archives != null) {
        if (!_.isEqual(
          [...post.archives].sort(), [...savedPostState.archives].sort()
        )) {
          if (savedPostState.archives.length === 0 && post.archives.length > 0)
            actions.push(EDITOR_ACTIONS.ARCHIVE)
          else if (savedPostState.archives.length > 0 && post.archives.length === 0)
            actions.push(EDITOR_ACTIONS.UNARCHIVE)
          else actions.push(EDITOR_ACTIONS.UPDATE_ARCHIVES)
        }

      } else {
        if (post.archives && post.archives.length > 0)
          actions.push(EDITOR_ACTIONS.ARCHIVE)
      }

      setActions(actions);

    };
    processActions();
  }, [post, savedPostState]);



  const handleUpdateArchives = async (id: number) => {
    if ((actions.includes(EDITOR_ACTIONS.ARCHIVE)
      || actions.includes(EDITOR_ACTIONS.UNARCHIVE)
      || actions.includes(EDITOR_ACTIONS.UPDATE_ARCHIVES))
      && id != null) {
      archivePost({
        variables: {
          id,
          archives: post.archives
        }
      }).then(() => {
        refechCount();
      });
    };
  };

  const handleMainActionClick = async () => {
    let id: number | undefined = post.id;

    if (actions.includes(EDITOR_ACTIONS.CREATE) && post.news_ticker_id != null) {
      let { data } = await handleCreateNewPost();
      if (data && data.createPost) id = data.createPost.id
      if (id) await handleUpdateArchives(id);
    } else if (actions.includes(EDITOR_ACTIONS.MODIFY)) {
      let { data } = await handleUpdatePost();
      if (data && data.post) id = data.post.id
      if (id)
        await handleUpdateArchives(id);
    } else {
      if (id)
        await handleUpdateArchives(id)
    }


    if (actions.includes(EDITOR_ACTIONS.CREATE) && post.news_ticker_id != null) {
      setPost(getNewPost());
    } else {
      navigate('/');
    }
  };

  const handleCreateNewPost = async () => {
    console.log(post.stock_value_change === getNewPost().stock_value_change);
    return await addPost({
      variables: {
        post: {
          news_ticker_id: post.news_ticker_id,
          news_date: post.news_date.toISOString(),

          title: post.title,
          content: post.content,
          content_type: post.content_type,

          stock_value_change: (post.stock_value_change === getNewPost().stock_value_change) ? null : post.stock_value_change,
        }
      }
    });
  };

  const handleUpdatePost = async (rejected: Boolean = false) => {
    if (!('id' in post)) throw new Error("Trying to update a post wiht no existing Id");
    return await updatePost({
      variables: {
        post: {
          id: post.id,
          news_ticker_id: post.news_ticker_id,
          news_date: post.news_date.toISOString(),

          title: post.title,
          content: post.content,
          content_type: post.content_type,

          stock_value_change: (post.stock_value_change === getNewPost().stock_value_change) ? null : post.stock_value_change,
          rejected
        }
      }
    });
  };

  const handleRejectPost = async () => {
    if ('id' in post) {
      await handleUpdatePost(true);
      if (post.id != null) await handleUpdateArchives(post.id);
      navigate('/');
    }
  }

  const handleDeletePost = async () => {
    let confirm = window.confirm('Are you sure you wish to delete this post?');
    console.log(confirm)
    if ('id' in post && confirm)
      await deletePost({ variables: { id: post.id } });
    navigate('/')
  };

  const handleAcknowledgeDelete = async () => {
    if ('id' in post)
      await updatePost({
        variables: {
          post: {
            id: post.id,
            aknowledge_delete: false
          }
        }
      });
    navigate('/')
  };

  console.log(post);
  return (
    <div className="row collection editor" >
      <div className="col s12">
        <div id="news-item-title" className="flow-text editable-title fr-box fr-inline" style={{ lineHeight: '1.3em', marginRight: '10px' }}>
          <FroalaEditor
            placeholderText='Title'
            // view={!post.active}
            value={post.title}
            onChange={(event: string) => {
              if (event !== undefined && post.active) console.log("Setting post title")
              if (event !== undefined && post.active)
                setPost(post => {
                  console.log(post)
                  return { ...post, title: event }
                });
            }}
          />
        </div>
      </div>

      <div className="col s12">
        <div id="news-item-content" className="flow-text editable fr-box fr-inline">
          <FroalaEditor
            placeholderText='Body'
            value={post.content}
            onChange={(event: string) => {
              if (event !== undefined && post.active) console.log("Setting post title")
              if (event != null && post.active)
                setPost(post => {
                  return { ...post, content: event }
                });
            }}
          />
        </div>
      </div>

      <div className="col s12 divider" />

      <div className="col s6 archive-checkboxes">

        <Box sx={{ my: 2 }}>
          <PostEditorStockValue
            post={post}
            cachedPost={savedPostState}
            setPost={setPost}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <DesktopDateTimePicker
            label="News original date"
            value={post.news_date}
            disabled={!post.active}
            onChange={(news_date) => {
              if (news_date == null) return;

              // create a time object with a specific time
              console.log("Setting post date")
              setPost(post => ({ ...post, news_date }))
            }}

            slots={{
              textField: (params) => <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  sx: { fontSize: "16px", fontWeight: 'bold' },  // Ajusta el tamaño de la tipografía del input
                }}
                size="small" variant='standard'

              />
            }}
            slotProps={{}}
          />
        </Box>


        {
          (post.active && post.archives) &&
          <ArchivesInput
            // onEmpty={ } // TODO: CHeck if post is pedding
            value={post.archives}
            onChange={(archives) => {
              console.log("Setting Post Archives" + archives)
              setPost(post => ({ ...post, archives }));
            }} />
        }

      </div>

      <div className="col s6">
        <Box sx={{ py: 4 }}>
          {
            (post.active) &&
            <Box
              sx={{
                my: 1,
                display: 'flex',
                justifyContent: 'left'
              }}
            >
              <Button
                ref={mainBtnRef}
                id="submit-button"
                className="waves-effect waves-light btn-large"
                disabled={post.news_ticker_id === null}
                variant="legacy" color="primary" size='large' onClick={handleMainActionClick}>{(actions.length > 0) ? actions.join(' & ') : 'Back'}</Button>
            </Box>
          }
          {
            ('id' in post && post.active && !post.rejected) &&
            <Box
              sx={{
                my: 1,
                display: 'flex',
                justifyContent: 'left'
              }}
            >
              <Button
                className="waves-effect waves-light btn-large"
                variant="legacy" color="reject" size='large' onClick={handleRejectPost}>REJECT</Button>
            </Box>
          }
          {
            ('id' in post && post.active) &&
            <Box
              sx={{
                my: 1,
                display: 'flex',
                justifyContent: 'left'
              }}
            >
              <Button
                className="waves-effect waves-light btn-large"
                variant="legacy" color="blue" size='large' onClick={handleDeletePost}>DELETE</Button>
            </Box>
          }
          {
            ('id' in post && !post.active && post.aknowledge_delete && post.submit_user_id === userId) &&
            <Box
              sx={{
                my: 1,
                display: 'flex',
                justifyContent: 'left'
              }}
            >
              <Button variant="legacy" color="blue" size='large' onClick={handleAcknowledgeDelete}>Acknowledge</Button>
            </Box>
          }
        </Box>
      </div>

      <div className="col s12">
        {(post.id !== undefined) && <PostEvents postId={post.id} />}
        <div />
      </div>
    </div >

  );
};

export default PostEditor;