import { useState, useEffect, useCallback } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Dayjs } from 'dayjs';

const GET_PRICE = gql`
  query MyQuery($date: String!, $symbol: String!) {
    getPrice(date: $date, symbol: $symbol) {
      price
      changePercent
    }
  }
`;

const formatValue = (price: number, changePercent: number) => `($${price.toFixed(2)}, ${changePercent.toFixed(2)}%)`;

interface UseStockValueProps {
  ticker: string | null;
  date: Dayjs;
  onFetchValue: (value: string) => void;
  onRefresh: (value: string) => void;
}

const useStockValue = ({ ticker, date, onFetchValue, onRefresh }: UseStockValueProps) => {
  const { data, loading, error, refetch } = useQuery<{
    getPrice: {
      price: number;
      changePercent: number;
    }
  }>(GET_PRICE, {
    fetchPolicy: 'network-only',
    variables: { date: date.format('YYYY-MM-DD'), symbol: ticker },
    skip: !ticker || !date,
  });

  useEffect(() => {
    if (data) {
      const formattedValue = formatValue(data.getPrice.price, data.getPrice.changePercent);
      onFetchValue(formattedValue);
    }
  }, [data, onFetchValue]);

  const handleRefresh = useCallback(async () => {
    if (ticker && date) {
      const { data } = await refetch();
      if (data) {
        const formattedValue = formatValue(data.getPrice.price, data.getPrice.changePercent);
        console.log(formattedValue)
        onRefresh(formattedValue);
      }
    }
  }, [ticker, date, refetch, onRefresh]);

  if (error) alert(error.message);

  return { handleRefresh, loading };
};

export default useStockValue;
