import React, { useContext } from 'react'
import { FiltersContext } from './FiltersProvider';

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Box, TextField } from '@mui/material';

const DateRangeFilterInput: React.FC = () => {
    const [filters, setFilters] = useContext(FiltersContext);

    return (
        <>
            <DesktopDatePicker
                label="From"

                // inputFormat="MM/DD/YY"
                value={filters.fromPostDate}
                onChange={(newValue) => {
                    if (newValue != null && newValue.isValid())
                        setFilters(f => ({ ...f, fromPostDate: newValue }));
                }}
                slots={{
                    textField:
                        (params) => <TextField
                            {...params}
                            sx={{
                                marginLeft: 1, width: 180, my: 1,
                                color: "white",
                                svg: { color: 'white' },
                                input: { color: ' white' },
                                label: { colo: 'white' },
                                "text-fill-color": "white !important",
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                }
                            }}

                            size="small"
                        />
                }}
            />
            <Box sx={{ display: `flex`, alignItems: `center` }}><ArrowRightIcon /></Box>
            <DesktopDatePicker
                label="Until"
                value={filters.untilPostDate}
                onChange={(newValue) => {
                    setFilters(f => ({ ...f, untilPostDate: newValue }));
                }}
                slots={{
                    textField:
                        (params) => <TextField

                            size="small"
                            {...params}
                            sx={{
                                marginLeft: 1, width: 180, my: 1,
                                color: "white",
                                svg: { color: 'white' },
                                input: { color: ' white' },
                                label: { colo: 'white' },
                                "text-fill-color": "white !important",
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                },

                            }}
                        />
                }}
            />
        </>
    )
}

export default DateRangeFilterInput