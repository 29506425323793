import React, { ComponentType, ReactNode } from 'react';

// Definimos el tipo para los proveedores con o sin parámetros
export type ProviderWithOptionalProps<T = any> = ComponentType<T> | [ComponentType<T>, T];

const withProviders = <P extends object>(
    Component: ComponentType<P>,
    providersWithParams: ProviderWithOptionalProps[]
): ComponentType<P> => {
    return providersWithParams.reverse().reduce((AccumulatedComponent, current) => {
        const [Provider, providerProps] = Array.isArray(current) ? current : [current, {}];
        return (props: P) => (
            <Provider {...providerProps}>
                <AccumulatedComponent {...props} />
            </Provider>
        );
    }, Component);
};

export default withProviders;