
import { AuthOptions, createAuthLink } from "aws-appsync-auth-link"; // Required package Buffer
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";

import appSyncConfig from "./aws-exports";

import { useAuth0 } from "@auth0/auth0-react";

import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  ApolloLink,
} from "@apollo/client";

import { cache } from "./ApolloInMemorycache";


if (process.env.REACT_APP_GRAPHQL_API_ENDPOINT === undefined) throw new Error('Env variable REACT_APP_GRAPHQL_API_ENDPOINT is undefiend');

const HTTPLINK = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_API_ENDPOINT });

export const ApolloConfiguration: React.FC<{ children: React.ReactNode }> = (props) => {
  const { getAccessTokenSilently } = useAuth0();

  if (process.env.REACT_APP_GRAPHQL_API_ENDPOINT === undefined) throw new Error('Env variable REACT_APP_GRAPHQL_API_ENDPOINT is undefiend');

  const auth: AuthOptions = {
    type: 'OPENID_CONNECT',
    jwtToken: async () => {
      var access_token = await getAccessTokenSilently();
      // console.log('Access Token: ', access_token);
      return access_token;
    },
  };

  const link = ApolloLink.from([
    createAuthLink({ url: process.env.REACT_APP_GRAPHQL_API_ENDPOINT, region: appSyncConfig.aws_appsync_region, auth }),
    createSubscriptionHandshakeLink({ url: process.env.REACT_APP_GRAPHQL_API_ENDPOINT, region: appSyncConfig.aws_appsync_region, auth }, HTTPLINK),
  ]);

  const client = new ApolloClient({
    cache,
    link,
    connectToDevTools: true
  });

  return (
    <ApolloProvider client={client}>
      {props.children}
    </ApolloProvider>
  )
};