import React from 'react';
import type { ComponentProps } from "react";

// Data Handling
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

// MUI
import { Autocomplete, TextField } from '@mui/material';
import _ from 'lodash';

export interface User {
    id: number
    name: string
}

const QUERY = gql`
    query Analysts {
        analysts {
            id
            name
          }
    }
`;

// {
//     "data": {
//         "analysts": [
//             {
//                 "id": 5,
//                 "name": "kidpollo",
//                 "__typename": "User"
//             },
//             {
//                 "id": 6,
//                 "name": "sibilino",
//                 "__typename": "User"
//             },
//             {
//                 "id": 7,
//                 "name": "capi03",
//                 "__typename": "User"
//             },
//             {
//                 "id": 8,
//                 "name": "mktwatcher",
//                 "__typename": "User"
//             },
//             {
//                 "id": 9,
//                 "name": "solrac1",
//                 "__typename": "User"
//             },
//             {
//                 "id": 10,
//                 "name": "sirluis",
//                 "__typename": "User"
//             },
//             {
//                 "id": 11,
//                 "name": "pilyppz",
//                 "__typename": "User"
//             },
//             {
//                 "id": 13,
//                 "name": "lia",
//                 "__typename": "User"
//             },
//             {
//                 "id": 14,
//                 "name": "keko",
//                 "__typename": "User"
//             },
//             {
//                 "id": 15,
//                 "name": "chris",
//                 "__typename": "User"
//             },
//             {
//                 "id": 16,
//                 "name": "prueba",
//                 "__typename": "User"
//             },
//             {
//                 "id": 17,
//                 "name": "Mari",
//                 "__typename": "User"
//             },
//             {
//                 "id": 18,
//                 "name": "ALBERTO",
//                 "__typename": "User"
//             },
//             {
//                 "id": 19,
//                 "name": "sergio4zan",
//                 "__typename": "User"
//             },
//             {
//                 "id": 20,
//                 "name": "miguel",
//                 "__typename": "User"
//             },
//             {
//                 "id": 22,
//                 "name": "cumming2",
//                 "__typename": "User"
//             },
//             {
//                 "id": 23,
//                 "name": "MktnewS",
//                 "__typename": "User"
//             },
//             {
//                 "id": 24,
//                 "name": "David",
//                 "__typename": "User"
//             }
//         ]
//     }
// }

const REMOVE = [5, 15, 24];
const PRIORITY = [14, 9, 20, 22, 18, 10];

// Function to reorder based on PRIORITY
// Function to reorder based on PRIORITY using lodash
const reorderBasedOnPriority = (array: any[], priority: number[]): any[] => {
    // First, remove objects with IDs in the REMOVE array
    const filteredArray = array.filter(obj => !_.includes(REMOVE, obj.id));

    // Partition the array into two groups: one that contains objects with IDs in PRIORITY and one that doesn't
    const [priorityObjects, otherObjects] = _.partition(filteredArray, obj => _.includes(priority, obj.id));

    // Order the objects in 'priorityObjects' based on the order of their IDs in PRIORITY
    const orderedPriorityObjects = _.sortBy(priorityObjects, obj => priority.indexOf(obj.id));

    // Sort the 'otherObjects' alphabetically by their 'name' property
    const sortedOtherObjects = _.sortBy(otherObjects, obj => obj.name.toLowerCase());

    // Concatenate the ordered objects with the alphabetically sorted 'otherObjects' to form the reordered array
    return orderedPriorityObjects.concat(sortedOtherObjects);
    };

const UserAnalystInputSelect: React.FC<Omit<ComponentProps<typeof Autocomplete<User>>, 'options' | 'renderInput'>> = (props) => {

    const { loading, error, data: { analysts } = { analysts: [] } } = useQuery<{ analysts: User[] }>(QUERY, {});

    if (loading) return null;
    if (error) return <>"An error has occurred: " + ${error.message}</>;

    return <Autocomplete<User>
        id="ticker-autocompleate"
        autoHighlight
        defaultValue={{ id: 0, name: 'All' }}
        getOptionLabel={(user) => user.name || ""}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        {...props}
        options={reorderBasedOnPriority(analysts, PRIORITY)}
        renderInput={(params) => <TextField
            {...params}
            label="Analyst"
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                // console.log(e);
                if (e.code === 'enter' && e.currentTarget.value) {
                    console.log(e.currentTarget.value)
                }
            }}
        />}
    />
};
export default UserAnalystInputSelect;