import React from "react";

import { useLocation } from "react-router-dom"; 
import styles from './AppBar.module.scss'

import {
    FilterAlt as FilterAltIcon,
} from "@mui/icons-material";

import Box from "@mui/system/Box";
import {
    Drawer,
    Button,
} from "@mui/material";

import FiltersBar from "./FilterBar";
import FilterDrawer from "./FilterDrawer";

export const BarCenterContent: React.FC<{
    onSearchClick: React.MouseEventHandler<HTMLButtonElement> | undefined; isSearchEnable?: boolean;
}> = ({ isSearchEnable, onSearchClick }) => {

    const [filtersDrawerOpen, setFiltersDrawer] = React.useState(false);
    const location = useLocation();
    var isHome = location.pathname === '/';
    var isNewsFeed = isHome || location.pathname === '/news';

    const handleFiltersDrawerToggle = () => {
        setFiltersDrawer(!filtersDrawerOpen);
    };
    if (!isNewsFeed) return null;
    return (
        <Box>
            <Box sx={{ display: `flex` }}>
                <Button
                    aria-label="open drawer"
                    // edge="start"
                    onClick={handleFiltersDrawerToggle}
                    sx={{ color: 'white', display: { sm: 'none' } }}
                >
                    Filters
                    <FilterAltIcon />
                </Button>
            </Box>
            <Box
                onScroll={(event) => { }}
                className={`${styles.filters} `}
                component="div"
                sx={{ display: { xs: 'none', sm: 'flex' }, overflowX: 'auto' }}
            >
                {<FiltersBar isSearchEnable={isSearchEnable} onSearchClick={onSearchClick} />}
            </Box>
            <Box component="div">
                <Drawer
                    container={window !== undefined ? () => window.document.body : undefined}
                    variant="temporary"
                    open={filtersDrawerOpen}
                    onClose={handleFiltersDrawerToggle}
                    anchor="right"
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                    }}
                >
                    <FilterDrawer isSearchEnable={isSearchEnable} onSearchClick={onSearchClick} />
                </Drawer>
            </Box>
        </Box>
    )
};
