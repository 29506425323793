import React, { useContext } from "react";

import { styled } from '@mui/material/styles';

import { FormControl } from "@mui/material";

import TickerInputSelectBase from "../commons/TickerInputSelect";

import { FiltersContext } from "./FiltersProvider";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    color: 'inherit',
    [theme.breakpoints.down('sm')]: {
        width: '100%'
    }
}));

const TickerInputSelect = styled(TickerInputSelectBase)(({ theme }) => ({
    marginLeft: 1, minWidth: 150, my: 1,
    color: "white",
    svg: { color: 'white' },
    input: { color: ' white' },
    label: {
        color: 'white'
    },
    "& .MuiInputLabel-root": {
        "&.MuiInputLabel-sizeSmall": {
            fontSize: "1.5rem",
        }
    },
    "text-fill-color": "white !important",
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(228, 219, 233, 0.25)',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(228, 219, 233, 0.25)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(228, 219, 233, 0.25)',
    },
    '.MuiSvgIcon-root ': {
        fill: "white !important",
    }
}));

const TickerFilter: React.FC = () => {
    const [filters, setFilters] = useContext(FiltersContext);

    return (
        <StyledFormControl size="small">
            <TickerInputSelect
                value={filters.ticker}
                onChange={(event, ticker) => {
                    console.log(ticker)
                    setFilters(f => ({ ...f, ticker }))
                }}
                size="small"
            />
        </StyledFormControl>
    );
};

export default TickerFilter;
