import React from 'react';
import { Box, Typography, Divider, List, ListItem, ListItemButton, ListItemText, IconButton } from '@mui/material';
import InputSelectStatus from '../PostsFeed/Components/Filters/InputSelectStatus';
import TickerFilter from '../PostsFeed/Components/Filters/TickersFilter';
import OrderByFilterInput from '../PostsFeed/Components/Filters/OrderByFiterInput';
import TickerSortOrderToggleBtn from '../PostsFeed/Components/Filters/TickerSortOrderToggleBtn';
import DateRangeFilterInput from '../PostsFeed/Components/Filters/DateRangeFilterInput';
import AnalystsFilterInput from '../PostsFeed/Components/Filters/AnalystsFilterInput';
import SearchIcon from "@mui/icons-material/Search";

interface Iprops {
  onSearchClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  isSearchEnable?: boolean;
}
const centerFlex = { justifyContent: 'center' };
const FilterDrawer: React.FC<Iprops> = (props) => {
  return (
    <Box
      // onClick={handleDrawerToggle}
      sx={{
        textAlign: 'center'
      }}>

      <Typography variant="h6" sx={{ my: 2 }}>
        Filters
      </Typography>
      <Divider />
      <List>
        <ListItem sx={{ ...centerFlex }}>
          <IconButton sx={{ color: (!props.isSearchEnable) ? 'white' : 'gray' }}
            onClick={props.onSearchClick} aria-label="delete" size="large">
            <SearchIcon fontSize="inherit" />
          </IconButton>
        </ListItem>
        <ListItem sx={{ ...centerFlex }}>
          <InputSelectStatus />
        </ListItem>
        <ListItem sx={{ ...centerFlex }}>
          <TickerFilter />
        </ListItem >
        <ListItem sx={{ ...centerFlex }}>
          <Box>
            <TickerSortOrderToggleBtn />
          </Box>
        </ListItem>
        <ListItem sx={{ ...centerFlex }}>
          <OrderByFilterInput />
        </ListItem>
        <ListItem sx={{ ...centerFlex }}>
          <Box >
            <DateRangeFilterInput />
          </Box>
        </ListItem>

        <ListItem sx={{ ...centerFlex }}>
          <Box>
            <AnalystsFilterInput />
          </Box >
        </ListItem>
        {
          [].map((item) => (
            <ListItem key={item} disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={item} />
              </ListItemButton>
            </ListItem>
          ))
        }
      </List >
    </Box >
  )
};

export default FilterDrawer;