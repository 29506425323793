import React, { createContext } from "react";

import { useQuery } from "@apollo/client";
import { Archive } from "../../../Types/Archive";
import { GET_ARCHIVES } from "../../../Querys";
import { useAuth0 } from "@auth0/auth0-react";

// TODO: Changes this to Enum
export enum FEEDS {
  ALL = "all",
  PENDING = 'pending', // Ticker of this user that havent being archive.
  ARCHIVED = 'archived',
  DELETED = 'deleted',
}

interface status {
  value: FEEDS;
  label: string;
}

export const BASE_STATUSES: status[] = [
  {
    value: FEEDS.ALL,
    label: "All",
  },
  {
    value: FEEDS.PENDING,
    label: "Pending",
  },
  {
    value: FEEDS.ARCHIVED,
    label: 'Archived'
  },
  {
    value: FEEDS.DELETED,
    label: 'Deleted'
  },
];

type FeedStateContext = {
  useFeedState: [FEEDS | number, React.Dispatch<React.SetStateAction<FEEDS | number>>]
  getFeedVariables: () => any;
}

export var FeedsStateContext = createContext<FeedStateContext>({
  useFeedState: [FEEDS.PENDING, () => { }],
  getFeedVariables: () => { throw new Error("Calling an initial unhandle Function for getFeedVariables") }
});

// Step 2: Create a context provider component
const FeedsStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

  const { user } = useAuth0();
  if (user === undefined || user.sub === undefined) throw new Error("User dont have permissions Screen");
  var userId = Number(user.sub.split('|')[1]);

  const useFeedState = React.useState<FEEDS | number>(FEEDS.PENDING);

  const { data: { archives } = { archives: [] } } = useQuery<{ archives: Archive[] }>(GET_ARCHIVES, {});

  const feed = useFeedState[0];

  const getFeedVariables = () => {
    switch (feed) {
      case FEEDS.PENDING:
        return { archives: [], active: true, tickersUserId: userId };
      case FEEDS.ARCHIVED:
        return { active: true, archives: archives.map(a => a.id) };
      case FEEDS.DELETED:
        return { active: false, aknowledgeDelete: false };
      case FEEDS.ALL:
        return { active: true };
      default:
        if (!Number.isNaN(feed)) // Archive Number
          return {
            archives: [feed]
          }
        throw new Error("Unhandled Feed Type" + feed);
    };
  };

  return (<FeedsStateContext.Provider value={{ useFeedState, getFeedVariables }}>{children}</FeedsStateContext.Provider>);
};

export default FeedsStateProvider;

