import React, { useContext } from 'react'

import { Post } from '../Types/Post';

import { Box, IconButton } from '@mui/material';

import { useQuery } from '@apollo/client';
import { GET_POSTS } from '../Querys';

import { postQuerySerializer, usePostsFeed } from '../Components/PostsFeed/Providers/PostsProvider';

import { FiltersContext } from '../Components/PostsFeed/Components/Filters/FiltersProvider';
import { FeedsStateContext } from '../Components/PostsFeed/Providers/FeedsStateProvider';
import { IndexContext } from '../Components/PostsFeed/Providers/IndexProvider';

import PostPreviewLegacy from '../Components/PostsFeed/Components/PostPreview/PostPreview';
import { useInView } from 'react-intersection-observer';
import Layout, { useLayout } from '../Components/Layout/Layout';
import { useNavigate } from 'react-router-dom';
import { CreateNewPostBtn } from '../Components/Layout/AppBar';

import {
  Menu as MenuIcon,
} from "@mui/icons-material";
import Counter from '../Components/PostsFeed/Components/commons/Counter';
import SearchBar from '../Components/Layout/SearchBar';
import { BarCenterContent } from '../Components/Layout/BarCenterContent';

const OFFSET_TO_LAST = 5;

const PostsFeedScreen = () => {

  // Auth we get the user information for this Screen
  const navigate = useNavigate();

  // Get the Index Context
  const [postIndex] = useContext(IndexContext);
  var myRef = React.useRef<any>(null);

  // Using the App Filters.
  const [filters] = useContext(FiltersContext);

  // Current Active Feeds.
  const { getFeedVariables } = useContext(FeedsStateContext);

  // Getting Posts Data.

  const { posts, total, nextPost } = usePostsFeed();
  const { fetchMore } = useQuery<{ posts: Post[] }>(GET_POSTS, {
    skip: true,
    variables: {
      ...postQuerySerializer(filters),
      ...getFeedVariables()
    }
  });


  var { ref: inViewRef, inView, } = useInView({
    threshold: .5,
  });

  React.useEffect(() => {
    (async () => {
      if (inView) {
        if (posts.length + 1 < total)
          await fetchMore({
            variables: {
              offset: posts.length,
              limit: 100,
            },
          });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  React.useEffect(() => {
    async function handleKeyDownOnNewsFeed(event: KeyboardEvent) {
      var key = event.key;

      if (key === 'Enter') {
        // handleSetNextIndex();
        nextPost();

        navigate(`/news/${postIndex}`);

        event.preventDefault();
        event.stopPropagation();
      }
    }

    if (postIndex !== null)
      document.addEventListener('keydown', handleKeyDownOnNewsFeed);
    return () => {
      document.removeEventListener('keydown', handleKeyDownOnNewsFeed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextPost]);

  React.useEffect(() => {
    if (myRef != null && myRef.current != null) {
      myRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      });
    };
  }, [postIndex]);

  //  Handling Post Click, If its the current Index set the index to the next post.
  // TODO: Handle This on the PostEditor so it get set to the next post when unmount.
  // TODO: Handle if the current index is the last, set it the pick the pass
  const onPostPreviewLegacyClick = (post: Post) => () => {
    nextPost(post);
    navigate(`/news/${post.id}`);
  };

  const setRefs = (post: Post, index: number) => (element: HTMLDivElement) => {
    var isRefreshIndexPost = index === posts.length - OFFSET_TO_LAST;
    if (isRefreshIndexPost)
      inViewRef(element);

    var isCurrentIndexPost = postIndex === post.id;
    if (isCurrentIndexPost)
      myRef.current = element;
  };

  console.log(posts);
  console.log(postIndex);

  return (
    <Layout menu={<Menu />}>
      <Box className='collection' sx={{ flexGrow: 1, overflow: 'hidden' }}>
        {
          posts.map(
            (post, index) =>
              <PostPreviewLegacy
                ref={setRefs(post, index)}
                key={post.id}
                post={post}
                active={true}
                selected={post.id === postIndex}
                onClick={onPostPreviewLegacyClick(post)}
                dateField={filters.getOrderDateBy()}
              />
          )
        }
      </Box >
    </Layout>
  )
};

const Menu = () => {
  const [isSearchEnable, setIsSearchEnable] = React.useState(false);
  const [, setIsDrawerOpen] = useLayout();

  return (
    <Box sx={{ display: 'flex', flex: '0 0 100%', flexDirection: 'column', padding: '0 10px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton color="inherit" onClick={() => {
            setIsDrawerOpen(isOpen => !isOpen);
          }} >
            <MenuIcon />
          </IconButton >

          <Counter />
        </Box>
        <BarCenterContent isSearchEnable={isSearchEnable} onSearchClick={() => { setIsSearchEnable(!isSearchEnable) }} />
        <CreateNewPostBtn />
      </Box>

      {/* Search row  */}
      {
        isSearchEnable && <SearchBar onClose={() => setIsSearchEnable(false)} />
      }
    </Box>
  )
}

export default PostsFeedScreen;