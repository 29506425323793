import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { Box, IconButton, InputBase } from '@mui/material';
import { alpha, styled } from '@mui/material';

import {
  Close as CloseIcon,
  Search as SearchIcon
} from '@mui/icons-material';

import { FiltersContext } from '../PostsFeed/Components/Filters/FiltersProvider';

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '50ch',
      '&:focus': {
        width: '60ch',
      },
    },
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchBar: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [filters, setFilters] = React.useContext(FiltersContext);

  useEffect(() => {
    console.log('FOCUS')
  }, []);

  const location = useLocation();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', flex: '0 0 100%' }}>
      <Search>
        <SearchIconWrapper >
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          value={filters.searchClause}
          onChange={(event) => {
            setFilters(f => ({ ...f, searchClause: event.target.value }))
          }}
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search', autoFocus: true }}
        />
      </Search>

      <IconButton onClick={() => {
        setFilters(f => ({ ...f, searchClause: null }))
        onClose();
      }}>
        <CloseIcon sx={{ color: 'white' }} />
      </IconButton>
    </Box>
  )
};

export default SearchBar;


